import './BlueBtn.css';

export const BlueBtn = ({ children }: any) => {
  const handleClick = () => {
    window.location.href = 'https://bridge.moonton.io';
  };

  return (
    <button
      className="blue-btn"
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

