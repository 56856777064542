import { Main } from './Main';
import { Mission } from './Mission';
import { Product } from './Product';
import { ForUsers } from './ForUsers';
import { Advantages } from './Advantages';
import { Roadmap } from './Roadmap';
import { Token } from './Token';
import { Join } from './Join';
import { Footer } from './Footer';
import { useEffect, useRef } from 'react';

export const MainPage = () => {
  const missionRef = useRef<HTMLDivElement>(null);
  const productRef = useRef<HTMLDivElement>(null);
  const forUsersRef = useRef<HTMLDivElement>(null);
  const advantagesRef = useRef<HTMLDivElement>(null);
  const roadmapRef = useRef<HTMLDivElement>(null);
  const tokenRef = useRef<HTMLDivElement>(null);
  const joinRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const hash = window.location.hash;
    const scrollToElement = (ref: React.RefObject<HTMLDivElement>) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    switch (hash) {
      case '#mission':
        scrollToElement(missionRef);
        break;
      case '#product':
        scrollToElement(productRef);
        break;
      case '#for-users':
        scrollToElement(forUsersRef);
        break;
      case '#advantages':
        scrollToElement(advantagesRef);
        break;
      case '#roadmap':
        scrollToElement(roadmapRef);
        break;
      case '#token':
        scrollToElement(tokenRef);
        break;
      case '#join':
        scrollToElement(joinRef);
        break;
      default:
        break;
    }
  }, []);
  return (
    <>
      <Main />
      <Mission ref={missionRef} />
      <Product ref={productRef} />
      <ForUsers />
      <Advantages ref={advantagesRef} />
      <Roadmap ref={roadmapRef} />
      <Token ref={ tokenRef} />
      <Join />
      <Footer />
    </>
  );
};


